import { Injectable } from '@angular/core';
import { Router, CanLoad, CanActivate } from '@angular/router';
import { EUserType } from '../enums/user.enum';
import { DataService } from '../services/data.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminAuthGuard implements CanActivate {
  constructor(public auth: AuthService, public router: Router, private dataService: DataService) { }
  canActivate(): boolean {
    const userType = this.dataService.getLocalStorageItem('user_type')
    if (userType == EUserType.SUPER_ADMIN) {
      return true;
    }
    this.router.navigate(['404']);
    return false;
  }
}
