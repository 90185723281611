import { Component, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './core/loader/loader.component';
import { MessageComponent } from './core/message/message.component';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs';
import { IModalConfig } from './core/interface/modal.interface';
import { UserListService } from './services/userlist.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, MessageComponent, LoaderComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showHeader: boolean = true;
  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute, private route: Router, private userListService: UserListService) { }

  ngOnInit() {
    this.route.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const firstChild = this.activatedRoute.firstChild;
      if (firstChild) {
        this.showHeader = firstChild.snapshot.routeConfig?.path === 'login'
          || firstChild.snapshot.routeConfig?.path === '404'
          || firstChild.snapshot.routeConfig?.path === 'set-password' ? false : true
      }
    })

  }

}
