import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EUserType } from '../../enums/user.enum';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { SubtenantService } from '../../services/subtenant.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MyProfileComponent, RouterModule, MatFormField, MatSelect, MatLabel, MatOption, FormsModule, ReactiveFormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic'
    }
  }]
})
export class HeaderComponent {
  isDropdown: boolean = false;
  isCollapsed: boolean = false;
  showProfile: boolean = false;
  menuItem: string = '';
  selectSubTenant = new FormControl('')
  EUserType = EUserType

  @ViewChild('dropdownRef', { static: false }) dropdownRef?: ElementRef;
  constructor(public dataService: DataService,
    public authService: AuthService,
    private renderer: Renderer2,
    private subtenantService: SubtenantService) {

    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.isDropdown && !this.dropdownRef?.nativeElement.contains(event.target)) {
        this.isDropdown = false;
      }
    })

  }

  ngOnInit() {
    if (this.authService.isUser) {
      this.onSubtenantValueChanges()
    }
  }

  onLogout(): void {
    this.authService.forceLogout()
  }

  dropdownToggleEvent(event: Event) {
    event.preventDefault();
    this.isDropdown = !this.isDropdown;
  }
  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }

  openMyProfile() {
    this.showProfile = !this.showProfile;
  }
  closeProfile(profileView: any) {
    this.showProfile = profileView;
  }
  onSubtenantValueChanges() {
    this.selectSubTenant.valueChanges.subscribe(res => {
      if (res) {
        this.subtenantService.selectedSubTenant.next(res)
      }
    })
  }

}
