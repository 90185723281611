import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { SuperAdminAuthGuard } from './guards/super-admin.guard';
import { CompanyAdminAuthGuard } from './guards/company-admin.guard';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { WabaGuard } from './guards/waba.guard';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: 'home',
        redirectTo: 'login'
    },
    {
        path: 'login',
        loadComponent: () => import('./components/admin-login/admin-login.component').then(comp => comp.AdminLoginComponent)
    },
    {
        path: 'set-password',
        loadComponent: () => import('./components/reset-password/reset-password.component').then(comp => comp.ResetPasswordComponent)
    },
    {
        path: 'change-password',
        loadComponent: () => import('./components/change-password/change-password.component').then(comp => comp.ChangePasswordComponent),
        canActivate: [AuthGuard]
    },
    {
        path: 'manage-company',
        loadComponent: () => import('./modules/admin/companies/manage-company/manage-company.component').then(comp => comp.ManageCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],
        data: {
            breadcrumb: 'Manage Company'
        }
    }, {
        path: "manage-company/add-company",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],
        data: {
            breadcrumb: 'Add Company'
        }
    }, {
        path: "manage-company/update-company/:companyId",
        loadComponent: () => import('./modules/admin/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
        canActivate: [AuthGuard, SuperAdminAuthGuard],
        data: {
            breadcrumb: 'Update Company'
        },
    }, {
        path: "my-profile",
        component: MyProfileComponent,
        canActivate: [AuthGuard, CompanyAdminAuthGuard],
        data: {
            breadcrumb: 'My Profile'
        }
    }, {
        path: "manage-userlist",
        loadComponent: () => import('./modules/company-admin/userlist/manage-userlist/manage-userlist.component').then(comp => comp.ManageUserlistComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Manage User List'
        }

    }, {
        path: "manage-userlist/upload-userlist",
        loadComponent: () => import('./modules/company-admin/userlist/add-userlist/add-userlist.component').then(comp => comp.AddUserlistComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Upload User List'
        }
    }, {
        path: "manage-userlist/view-userlist/:userlistId/:userlistName",
        loadComponent: () => import('./components/view-users/view-users.component').then(comp => comp.ViewUsersComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'View Userlist'
        }
    }, {
        path: "manage-campaigns",
        loadComponent: () => import('./modules/company-admin/campaigns/manage-compaigns/manage-compaigns.component').then(comp => comp.ManageCompaignsComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Manage Campaigns'
        }
    }, {
        path: "manage-campaigns/add-campaign",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Add Campaign'
        }
    }, {
        path: "manage-campaigns/update-campaign/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/add-campaign/add-campaign.component').then(comp => comp.AddCampaignComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Update Campaign'
        }
    }, {
        path: "manage-campaigns/messsage-status/:campaignId",
        loadComponent: () => import('./modules/company-admin/campaigns/message-status/message-status.component').then(comp => comp.MessageStatusComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Message Status'
        }
    }, {
        path: "manage-templates",
        loadComponent: () => import('./modules/company-admin/templates/manage-template/manage-template.component').then(comp => comp.ManageTemplateComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadcrumb: 'Manage Templates'
        }
    }, {
        path: "manage-templates/create-templates",
        loadComponent: () => import('./modules/company-admin/templates/create-template/create-template.component').then(comp => comp.CreateTemplateComponent),
        canActivate: [AuthGuard, CompanyAdminAuthGuard, WabaGuard],
        data: {
            breadCrumb: 'Create Templates'
        }
    },
    {
        path:"waba-verification",
        loadComponent: () => import('./modules/company-admin/waba-verify/waba-verify.component').then(comp => comp.WabaVerifyComponent),
    },
    { path: '404', component: PageNotFoundComponent },
    { path: '**', redirectTo: '404' },

];
