import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { AdminHeaderInterceptor } from './services/admin-header.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), provideHttpClient(withInterceptorsFromDi()), provideAnimationsAsync(),{
      provide: HTTP_INTERCEPTORS,
      useClass: AdminHeaderInterceptor,
      multi: true,
    }]
};
