import { Component, ViewChild } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './core/loader/loader.component';
import { MessageComponent } from './core/message/message.component';
import { AuthService } from './services/auth.service';
import { filter } from 'rxjs';
import { IModalConfig } from './core/interface/modal.interface';
import { UserListService } from './services/userlist.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, MessageComponent, LoaderComponent, NgIf],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  showHeader: boolean = false;
  currentUrl: string = ''
  constructor(public authService: AuthService, private activatedRoute: ActivatedRoute, private route: Router, private userListService: UserListService) { }

  ngOnInit() {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url
        this.showHeader = this.authService.isLoggedIn && this.currentUrl != '/login' && this.currentUrl != '/set-password' ? true : false
      }
    })

  }

}
