import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, GuardResult, MaybeAsync, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { UserService } from "../services/user.service";
import { Observable, switchMap, take } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class WabaGuard implements CanActivate {
    constructor(private userService: UserService) { }

    canActivate(): Observable<boolean | UrlTree> {
        return this.userService.isWabaVerified()
    }
}