<nav class="navbar w-100 p-0">
  <div class="flex-fill">
    <div class="text-center menu-top-header bg-white p-3">
      <a [routerLink]="['/']" routerLinkActive="router-link-active"><img src="../../../assets/images/logo.png"
          class="logo" alt="logo"></a>
    </div>
    <div class="justify-content-between pt-3">
      <ul class="navbar-nav">
        @if(authService.isUser){
        <li class="nav-item">
          <a routerLink="/manage-userlist" routerLinkActive="active">User List</a>
        </li>
        <li class="nav-item ">
          <a routerLink="/manage-templates" routerLinkActive="active">Templates</a>
        </li>
        <li class="nav-item">
          <a routerLink="/manage-campaigns" routerLinkActive="active">Campaigns</a>
        </li>
        }@else{
        <li class="nav-item">
          <a routerLink="/manage-company" routerLinkActive="active">Companies</a>
        </li>
        }

      </ul>

    </div>
  </div>
</nav>

<div class="top-header">
  <div class=" text-center text-lg-end">
    <div class="position-relative pointer" #dropdownRef (click)="dropdownToggleEvent($event)">
      <a class="nav-link dropdown-toggle font-14">
        {{dataService.getLocalStorageItem('user_name')}}
      </a>

    </div>
    <ul class="dropdown-menu font-14" [ngClass]="isDropdown?'show' :'hide'">
      <li>
        @if(authService.isUser){
        <a class="dropdown-item" routerLink='/my-profile'>My Profile</a>
        }
        <a class="dropdown-item" routerLink='/change-password'>Change Password</a>
        <a class="dropdown-item pointer" (click)="onLogout()">Logout</a>
      </li>
    </ul>
  </div>
</div>

<div [ngClass]="showProfile ?'my-profile-modal' : 'hide-profile'">
  <app-my-profile (closedProfile)="closeProfile($event)"></app-my-profile>
</div>