import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterLinkActive, RouterModule } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';
import { UtilityService } from '../../services/utils.service';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { EUserType } from '../../enums/user.enum';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MyProfileComponent, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  isDropdown:boolean = false;
  isCollapsed : boolean = false;
  showProfile:boolean = false;
  menuItem:string='';

  EUserType = EUserType

  @ViewChild('dropdownRef', { static: false }) dropdownRef?: ElementRef;
  constructor(public dataService: DataService,
    public authService: AuthService,
    private utilityService: UtilityService,
    private renderer: Renderer2) {

    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.isDropdown && !this.dropdownRef?.nativeElement.contains(event.target)) {
        this.isDropdown = false;
      }
    })
  
  }

  ngOnInit(){
    
  }

  onLogout(): void {
      this.authService.forceLogout()
  }

  dropdownToggleEvent(event: Event) {
    event.preventDefault();
    this.isDropdown = !this.isDropdown;
  }
  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }

  openMyProfile(){
    this.showProfile = !this.showProfile;
  }
  closeProfile(profileView :any){
    this.showProfile = profileView;
  }

}
