import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataService } from './data.service';

@Injectable()
export class AdminHeaderInterceptor implements HttpInterceptor {

  constructor(private dataService:DataService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const admin = this.dataService.getLocalStorageItem('admin')
    if(admin){
      const authRequest = request.clone({
        setHeaders: {
          admin :admin
        }
      })
      return next.handle(authRequest);
    }else{
      return next.handle(request);
    }
    }

}
